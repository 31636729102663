import styled from "styled-components";
import Typography from "../Typography";

export const Wrapper = styled("header")(({ theme }) => ({
  width: "100%",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "125%",
  letterSpacing: "1px",
  color: theme.colors.text.paritetBrandColor,
  marginBottom: "-3px",

  [theme.breakpoint.down.lg]: {
    fontSize: "10px",
  },
}));

export const Container = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(3.5, 6, 2.5),
  borderBottom: `2px solid ${theme.colors.text.softGray}`,
  [theme.breakpoint.down.lg]: {
    padding: theme.spacing(1.5, 3),
  },
}));

export const LogoContainer = styled("a")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  gap: theme.spacing(1.5),
  [theme.breakpoint.down.lg]: {
    gap: theme.spacing(0.5),
  },
  fontFamily: "Russo One",
  svg: {
    flexShrink: "0",
    width: theme.spacing(15),
    height: theme.spacing(5),

    [theme.breakpoint.down.lg]: {
      width: theme.spacing(7.75),
      height: theme.spacing(2.5),
    },
  },
}));

export const Contacts = styled("div")(({ theme }) => ({
  color: theme.colors.text.midGray,
  fontSize: "14px",
  lineHeight: "18px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  [theme.breakpoint.down.lg]: {
    flexDirection: "column",
  },
}));

export const Schedule = styled("div")(({ theme }) => ({
  [theme.breakpoint.down.lg]: {
    display: "none",
  },
}));

export const ScheduleMin = styled("div")(({ theme }) => ({
  [theme.breakpoint.up.lg]: {
    display: "none",
  },
}));
